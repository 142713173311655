<template>
  <!-- BEGIN: Content-->
  <div
    v-if="user && convo && round"
    class="app-content content "
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ user.full_name }}
                <strong
                  v-if="round == 6"
                  class="custom-badge bg-light-success ms-2"
                ><span class="text-dark">Mark:</span> {{ user.eval_data.avg_grade }}</strong>
                <br>
                <small>{{ convo.title }} · Applicant</small>
                <br>
                <span
                  v-if="round == 5 && (user.eval_data.competent || user.eval_data.competent === false)"
                  class="text-success"
                >
                  <i data-feather="circle" /> <small>Round 0 - Evaluated</small>
                </span>
                <span
                  v-if="round == 5 && user.eval_data.competent === null"
                  class="text-danger"
                >
                  <i data-feather="circle" /> <small>Round 0 - Pending evaluation</small>
                </span>
                <span
                  v-if="round == 6 && user.eval_data.grading_date"
                  class="text-success"
                >
                  <i data-feather="circle" /> <small>Round 1 - Evaluated</small>
                </span>
                <span
                  v-if="round == 6 && !user.eval_data.avg_grade"
                  class="text-danger"
                >
                  <i data-feather="circle" /> <small>Round 1 - Pending evaluation</small>
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to applicants list"
              @click="$router.push({ name: 'evaluator.calls.evaluations', params: { page } })"
            ><i data-feather="chevron-left" /></a>
            <a
              class="btn btn-primary ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Download zip"
              @click="downloadZip"
            >
              <a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                :class="{'d-none': !loading}"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Loading export..."
              ><i
                data-feather="loader"
              /></a>
              <span
                class="loop-loading"
                :class="{'d-none': loading}"
              >
                <i
                  data-feather="file"
                  class="me-25"
                />
              </span>

              {{ !loading ? 'Download zip' : 'Exporting applicant...' }}
            </a>
          </div>
        </div>
      </div>

      <!-- IMPORTANTE: las acciones que se realizan durante una ronda puede consultarse en rondas posteriores pero NO modificarlas -->

      <!-- aparece en ronda 1 -->
      <div
        v-if="round >= 6"
        class="card"
      >
        <div
          v-b-toggle="`collapseFormReport`"
          class="card-header"
        >
          <h3 class="cart-title">
            <a data-action="collapse">Evaluation: click here to unfold the form <i data-feather="chevron-down" /></a>
          </h3>
        </div>
        <b-collapse
          :id="`collapseFormReport`"
        >
          <div class="card-content">
            <div class="card-body">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label required"
                >Strengths</label>
                <textarea
                  id=""
                  v-model="user.eval_data.stregths"
                  name=""
                  cols=""
                  rows="4"
                  class="form-control"
                />
              </div>
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label required"
                >Weaknesses</label>
                <textarea
                  id=""
                  v-model="user.eval_data.weaknesses"
                  name=""
                  cols=""
                  rows="4"
                  class="form-control"
                />
              </div>
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label required"
                >General comments</label>
                <p class="form-text mb-1">
                  (might be sent to the applicant: please be constructive)
                </p>
                <textarea
                  id=""
                  v-model="user.eval_data.general_comments"
                  name=""
                  cols=""
                  rows="4"
                  class="form-control"
                />
              </div>
              <div class="mb-1 form-group required col-6">
                <label
                  for=""
                  class="form-label required"
                >Mark from 1 to 10 the track record in the past 5 years & research plans for the future following the criteria below:</label>
                <div class="col-sm-2 mb-1">
                  <input
                    v-model="user.eval_data.avg_grade"
                    type="number"
                    min="0"
                    max="10"
                    class="form-control"
                  > <!-- limitar este campo: valor entre 0 y 10, máximo un decimal -->
                </div>
                <b-tabs>
                  <b-tab active>
                    <template #title>
                      1
                    </template>
                    <div
                      style="border: solid 1px #a3903f; border-radius:10px;"
                      class="p-1"
                    >
                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Essentially </span><strong>non-competitive</strong> research outputs for<strong />international standards.
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <strong>No experience</strong> in leading<span> research projects.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <strong>Lack of evidence</strong> of engagement with<span> internationally recogni</span>z<span>ed groups</span>.
                      </p>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      2 to 3
                    </template>
                    <div
                      style="border: solid 1px #a3903f; border-radius:10px;"
                      class="p-1"
                    >
                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <strong>General</strong><span><strong>ly </strong></span><strong /><span>research </span>outputs with <span>quality that is </span><span><strong>recogni</strong></span><strong>z</strong><span><strong>ed internationally</strong></span><span> in terms of originality, significance and rigor.</span> <strong>Minor d</strong><span><strong>oubts</strong></span><span> about </span><span><strong>personal contribution</strong></span><span> to outputs and ideas in joint work</span>.
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <strong>Some experience</strong> in leading<span> research projects.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <strong>Minor </strong><strong /><strong>evidence</strong> of engagement with<span> internationally recogni</span>z<span>ed groups</span>.
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <strong>Minor</strong> strategic planning of research
                      </p>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      3 to 5
                    </template>
                    <div
                      style="border: solid 1px #a3903f; border-radius:10px;"
                      class="p-1"
                    >
                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                        <strong>Mostly </strong>outputs of <span>research quality that is </span><strong>recognized</strong> <span><strong>internationally </strong></span><span>in terms of originality, significance and rigor</span>; <span>and </span><span><strong>also possible</strong></span><span> research </span>outputs of <span>quality that is </span><span><strong>internationally excellent</strong></span>. <strong>No </strong><span><strong>doubts</strong></span><span> about </span><span><strong>personal contribution</strong></span><span> to outputs and ideas in joint work.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                        <strong>Experience</strong> in leading<span> research projects.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                        <span><strong>Some engagement</strong></span><span> with the international community in the field.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                        <span><strong>Some thought</strong></span><span> given to planning</span> of research.
                      </p>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      5 to 7
                    </template>
                    <div
                      style="border: solid 1px #a3903f; border-radius:10px;"
                      class="p-1"
                    >
                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span><strong>Clear outputs</strong></span><span> with research quality that is </span><span><strong>internationally excellent</strong></span><span> in terms of originality, significance and rigor, but which falls short of the highest standards of excellence.</span> <span><strong>Some evidence</strong></span><span> of </span><span><strong>personal contribution</strong></span><span> to ideas in joint outputs.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Demonstrated ability to propose and conduct </span><span><strong>national</strong></span><span> research projects.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span><strong>Some national awards</strong></span><span> or prizes. Good engagement with the international community in the field.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Adequate planning of research</span> producing results of <span><strong>international</strong></span> <span><strong>excellence</strong></span>.
                      </p>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      7 to 9
                    </template>

                    <div
                      style="border: solid 1px #a3903f; border-radius:10px;"
                      class="p-1"
                    >
                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Publication record </span><span><strong>contains</strong></span><span> outputs with research quality that is </span><span><strong>internationally excellent</strong></span><span> in terms of originality, significance and rigor</span>,<span> but which falls short of the highest standards of excellence</span> <span>and </span><span><strong>also possible</strong></span><span> research quality that is </span><span><strong>world-leading</strong></span>. <strong>E</strong><span><strong>vidence</strong></span><span> of </span><span><strong>personal contribution</strong></span><span> to ideas</span>.
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Demonstrated ability to propose and conduct </span><span><strong>national</strong></span> and <strong>possible international</strong> <span>research projects.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Some </span><span><strong>national and international awards</strong></span><span> and prizes and other measures of </span><span><strong>international esteem</strong></span>.
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Research project </span><span><strong>timely, innovative</strong></span><span> research, and </span><span><strong>well planned</strong></span><strong>, </strong><span>which will </span>certainly <span>be of </span><span><strong>international</strong></span> <span><strong>excellence</strong></span> and <strong>likely to be world-leading</strong><strong>.</strong>
                      </p>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      10
                    </template>
                    <div
                      style="border: solid 1px #a3903f; border-radius:10px;"
                      class="p-1"
                    >
                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span><strong>Clear</strong></span><span> record of outputs and </span><span><strong>clear evidence of personal contribution</strong></span><span> to research quality that is </span><span><strong>world-leading</strong></span><span> in terms of originality, significance and rigor.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Demonstrated ability to propose and conduct </span><span><strong>international</strong></span><span> research projects.</span>
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Exposure to </span><span><strong>world leading</strong></span><span> research teams. Very impressive record of </span><span><strong>international awards and esteem</strong></span>.
                      </p>

                      <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                        <span>Research project </span><span><strong>highly innovative and timely</strong></span><span>, and </span><span><strong>planned in careful detail</strong></span>, that will <strong>definitively</strong> produce <strong>world-leading</strong> research.
                      </p>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>

              <div
                v-if="current_access == round || convo.current_access_round"
                class="mb-1"
              >
                <button
                  class="btn btn-outline-success"
                  @click="rateApplicant({
                    stregths: user.eval_data.stregths,
                    weaknesses: user.eval_data.weaknesses,
                    general_comments: user.eval_data.general_comments,
                    avg_grade: user.eval_data.avg_grade,
                  })"
                >
                  <i data-feather="save" /> Save draft
                </button>
                <button
                  class="btn btn-success ms-1"
                  @click="rateApplicant({
                    stregths: user.eval_data.stregths,
                    weaknesses: user.eval_data.weaknesses,
                    general_comments: user.eval_data.general_comments,
                    avg_grade: user.eval_data.avg_grade,
                    grading_date: true
                  })"
                >
                  <i data-feather="send" /> Submit evaluation
                </button>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <hr>
      <!-- aparece en ronda 1 -->

      <div
        class="row pb-2"
      >
        <div
          v-if="round == 5"
          class="col"
        >
          <!-- estas opciones són sólo para la ronda cero -->
          <!-- por defecto -->
          <div
            class="btn-group"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              class="btn btn-outline-success"
              :class="{'btn-success': user.eval_data.competent}"
              @click="rateApplicant({
                competent: true
              })"
            >
              Competitive
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              :class="{'btn-danger': user.eval_data.competent === false}"
              @click="rateApplicant({
                competent: false
              })"
            >
              Uncompetitive
            </button>
          </div>
        </div>
        <div class="col">
          <div class="viewform__actions--buttons pb-2">
            <a
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Display all blocks"
              @click="toggleAllSections(false)"
            ><i data-feather="maximize-2" /> Display all</a>
            <a
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse all blocks"
              @click="toggleAllSections(true)"
            ><i data-feather="minimize-2" /> Collapse all</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <PreviewSection
            v-for="(section, index) in sections"
            :key="section.id"
            :section="section"
            :user-id="user.user_id"
            :index="(index + 1)"
          />
        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div></template>

<script>
import PreviewSection from '@/views/convos/partials/preview/PreviewSection.vue'
import { BTab, BTabs, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    PreviewSection,
    BCollapse,
    BTab,
    BTabs,
  },
  data() {
    return {
      user: {},
      round: 0,
      convo: {},
      loading: false,
      page: null,
    }
  },
  computed: {
    ...mapGetters({
      sections: 'convo/sections',
    }),
    current_access() {
      return this.convo.current_access ? this.convo.current_access.type : null
    },
  },
  async mounted() {
    this.page = this.$route.params.page
    if (!this.$route.params.user || !this.$route.params.round || !this.$route.params.convo) {
      this.$router.push({ name: 'evaluator.calls.evaluations', params: { page: this.page } })
    } else {
      this.user = this.$route.params.user
      this.round = this.$route.params.round
      this.convo = this.$route.params.convo

      this.$store.dispatch('convo/fetchUserSections', [this.$route.params.convo.id, this.user.user_id])

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    }
  },
  methods: {
    toggleAllSections(closed) {
      this.$store.dispatch('preview/toggleSections', closed)
      if (!closed) {
        this.$store.dispatch('preview/loadAllGroups')
      }
    },
    async downloadZip() {
      this.loading = true

      await this.$store.dispatch('convo/downloadZipApplicants', {
        round: this.round,
        convo: this.convo.id,
        candidate: this.user.user_id,
      })

      this.loading = false
    },
    rateApplicant(data) {
      if (this.current_access == this.round || this.convo.current_access_round) {
        if ((data.grading_date && data.stregths && data.weaknesses && data.avg_grade && data.general_comments) || !data.grading_date) {
          Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/evaluate-applicant`, {
            userId: this.user.user_id,
            confirm: this.current_access > 6,
            ...data,
          }).then(() => {
            this.$toastr.success('', 'Applicant status updated successfully!')
            if (data.competent) {
              this.user.eval_data.competent = data.competent
            }

            if (data.grading_date) {
              this.$router.push({ name: 'evaluator.calls.evaluations', params: { page: this.page } })
            }
          })
        } else {
          this.$toastr.error('', 'Please, fill up the required fields')
        }
      } else {
        this.$toastr.error('', 'The deadline for this round has ended', {
          timeOut: 0,
        })
      }
    },
  },
}
</script>
